import get from '@invitato/helpers/dist/getLocale';

export default {
  back: get('Kembali', 'Back'),
  button: get('LIHAT POTRET KAMI', 'MORE CAPTURE OF US'),
  ayatName: get('Matius 19:6', 'Colossians 3:14 NKJV'),
  ayatNameMuslim: get('Q.S. Ar-Rum: 21', 'Q.S. Ar-Rum: 21'),
  ayat: get(
    `Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia.`,
    `But above all these things put on love, which is the bond of perfection.`,
  ),
  ayatMuslim: get(
    `Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan diantaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.`,
    `And of His signs is that He created for you from yourselves mates that you may find tranquility in them, and He placed between you affection and mercy. Indeed in that are signs for a people who give thought.`,
  ),
};