import get from '@invitato/helpers/dist/getLocale';

export default {
  title: get('Waktu & <br />Tempat', 'Place &<br /> Time'),
  akad: get('AKAD NIKAH', 'AKAD NIKAH'),
  holy: get('PEMBERKATAN', 'HOLY MATRIMONY'),
  reception: get('RESEPSI PERNIKAHAN', 'WEDDING RECEPTION'),
  subtitle: get('Waktu & Tempat', 'Place & Time'),
  place: get('Tempat', 'Place'),
  time: get('Waktu', 'Time'),
  date: get('Tanggal', 'Date'),
  seeLocation: get('Lihat Lokasi', 'See Location'),
};
