import React from 'react';
import { string, array } from 'prop-types';
import {
  Box,
  Text,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Accordion,
} from '@chakra-ui/react';

import useInvitation from '@/hooks/useInvitation';
import LazyImage from '@/components/Common/LazyImage';
import ViewLocationButton from '@/components/Common/Buttons/ViewLocation';

import { BUTTON_PROPS } from '@/constants/colors';

import txt from './locales';

function WeddingDetail({ items, lang }) {
  const invitation = useInvitation();

  return (
    <Accordion defaultIndex={invitation ? [items.length - 1] : [0]}>
      {items
        .filter((x) => x.show)
        .map((item) => {
          const {
            date,
            dresscode,
            dresscodeUrl,
            imgUrl,
            info,
            location,
            locationRoad,
            locationUrl,
            time,
            title,
          } = item;

          return (
            <AccordionItem key={title} border="none">
              <AccordionButton {...BUTTON_PROPS} padding="0">
                <Box
                  padding="8px"
                  flex="1"
                  textAlign="left"
                  borderLeft="2px solid"
                  borderLeftColor="mainColorText"
                  color="mainColorText"
                >
                  <Text
                    fontSize="10px"
                    fontFamily="bodyAlternative"
                    textTransform="uppercase"
                    letterSpacing="2px"
                  >
                    {txt.subtitle[lang]}
                  </Text>
                  <Text marginTop="4px" fontSize="sm" fontWeight="bold">
                    {title}
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Box>
                  {imgUrl && (
                    <LazyImage
                      margin="8px 0"
                      border="2px solid"
                      borderColor="mainColorText"
                      src={imgUrl}
                      height="200px"
                      width="200px"
                      objectFit="cover"
                      objectPosition="center"
                    />
                  )}
                  <Text
                    fontSize="md"
                    color="mainColorText"
                    dangerouslySetInnerHTML={{ __html: `${txt.date[lang]}: ${date}` }}
                  />
                  <Text
                    fontSize="md"
                    color="mainColorText"
                    dangerouslySetInnerHTML={{ __html: `${txt.time[lang]}: ${time}` }}
                  />
                  {/* Dresscode image & Text */}
                  {dresscode && (
                    <Text fontSize="md" color="mainColorText">
                      Dresscode: {dresscode}
                    </Text>
                  )}
                  {dresscodeUrl && <LazyImage borderRadius="8px" width="80%" src={dresscodeUrl} />}
                  {info && (
                    <Text
                      fontSize="md"
                      fontStyle="italic"
                      color="mainColorText"
                      dangerouslySetInnerHTML={{ __html: info }}
                    />
                  )}
                  {invitation && location && (
                    <Text fontSize="md" marginTop="12px" color="mainColorText">
                      {txt.place[lang]}: {location}
                    </Text>
                  )}
                  {invitation && locationRoad && (
                    <Text fontSize="sm" fontStyle="italic" color="mainColorText">
                      <span dangerouslySetInnerHTML={{ __html: locationRoad }} />
                    </Text>
                  )}
                  {/* Button Action */}
                  {locationUrl && (
                    <ViewLocationButton
                      marginTop="16px"
                      size="sm"
                      bgColor="bgAlternative"
                      color="white"
                      textTransform="uppercase"
                      fontWeight="normal"
                      letterSpacing="2px"
                      fontFamily="body"
                      text={txt.seeLocation[lang]}
                      href={locationUrl}
                      {...BUTTON_PROPS}
                    />
                  )}
                </Box>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
    </Accordion>
  );
}

WeddingDetail.propTypes = {
  items: array,
  lang: string,
};

WeddingDetail.defaultProps = {
  items: [],
  lang: 'id',
};

export default React.memo(WeddingDetail);
