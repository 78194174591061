import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { string } from 'prop-types';

import { Button } from '@chakra-ui/react';
import useGuestInfo from '@/usecase/use-guest-info';

import { EPOCH_START_EVENT, EPOCH_RESEPSI_START, GOOGLE_MAPS_ADDRESS_AKAD, GOOGLE_MAPS_ADDRESS, THE_BRIDE, EPOCH_END_AKAD, EPOCH_END_EVENT } from '@/constants';

dayjs.extend(utc);

function AddToCalendarButton({ text, ...rest }) {
  const { shift } = useGuestInfo();

  const start = shift == 1 ? EPOCH_START_EVENT : EPOCH_RESEPSI_START
  const end = shift == 1 ? EPOCH_END_AKAD : EPOCH_END_EVENT
  const loc = shift == 1 ? GOOGLE_MAPS_ADDRESS_AKAD : GOOGLE_MAPS_ADDRESS
  
  const handleClickAddToCalendar = () => {
    const title = `Wedding of ${THE_BRIDE} by Invitato.net`;
    const finalURL = `text=${encodeURIComponent(title)}`;
    const location = `location=${loc}`;

    const startDate = dayjs(start * 1000)
      .utc(true)
      .format('YYYYMMDDTHHmmss');
    const endDate = dayjs(end * 1000)
      .utc(true)
      .format('YYYYMMDDTHHmmss');

    window.open(
      `https://www.google.com/calendar/render?action=TEMPLATE&${finalURL}&dates=${startDate}/${endDate}&${location}`,
      '_blank',
    );
  };
  return (
    <Button {...rest} onClick={handleClickAddToCalendar}>
      {text}
    </Button>
  );
}

AddToCalendarButton.propTypes = {
  text: string.isRequired,
};

export default AddToCalendarButton;
