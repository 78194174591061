import React from 'react';
import { Box, Center, Text, Heading, AspectRatio } from '@chakra-ui/react';

import InstagramButton from '@/components/Common/InstagramButton';
import WithAnimation from '@/components/Common/WithAnimation';
import DividerComponent from '@/components/Common/Divider';
import Image from '@/components/Common/LazyImage';

import { IMG_COUPLE } from '@/constants/assets';
import {
  GIRL_NAME,
  BOY_NAME,
  IG_GIRL,
  IG_BOY,
  GIRL_PARENT_NAME,
  BOY_PARENT_NAME,
  IS_BOY_FIRST,
} from '@/constants';

function SinglePhoto({ ...rest }) {
  const renderText = ({ name, instagramId, parentName, nameProps = {} }) => {
    return (
      <Box textAlign="center">
        <Heading
          size="2xl"
          lineHeight="90%"
          margin="8px 0"
          fontWeight="normal"
          color="secondaryColorText"
          dangerouslySetInnerHTML={{ __html: name.replace(', ', ', <br/>') }}
          {...nameProps}
        />
        <DividerComponent width="50%" />
        <Text
          fontSize="sm"
          color="mainColorText"
          dangerouslySetInnerHTML={{ __html: parentName }}
        />
        <Center margin="24px 0">
          <WithAnimation>
            <InstagramButton
              id={instagramId}
              text={instagramId}
              color="white"
              onlyIcon={false}
              size="sm"
              fontFamily="body"
              fontWeight="normal"
              bgColor="bgAlternative"
              letterSpacing="2px"
              boxShadow="xl"
            />
          </WithAnimation>
        </Center>
      </Box>
    );
  };

  const renderBoy = () => {
    return renderText({
      name: BOY_NAME,
      instagramId: IG_BOY,
      parentName: BOY_PARENT_NAME,
    });
  };

  const renderGirl = () => {
    return renderText({
      name: GIRL_NAME,
      instagramId: IG_GIRL,
      parentName: GIRL_PARENT_NAME,
    });
  };

  return (
    <Box bgColor="bgPrimary" padding="62px 0 42px 0" {...rest}>
      <Box bgColor="bgSecondary" height="250px" paddingTop="24px">
        <Heading
          textAlign="center"
          color="alternativeColorText"
          fontSize="36px"
          fontWeight="normal"
        >
          {IS_BOY_FIRST ? 'Groom & Bride' : 'Bride & Groom'}
        </Heading>
      </Box>
      <WithAnimation left>
        <AspectRatio
          display="block"
          margin="0 auto"
          marginTop="-130px"
          transform="translateX(35px)"
          zIndex={2}
          ratio={3 / 4}
          width="70%"
          maxW="200px"
        >
          <Image src={IMG_COUPLE} />
        </AspectRatio>
      </WithAnimation>
      <WithAnimation>
        <AspectRatio
          display="block"
          margin="0 auto"
          marginTop="-290px"
          zIndex={2}
          ratio={3 / 4}
          width="70%"
          maxW="230px"
        >
          <Image src={IMG_COUPLE} />
        </AspectRatio>
      </WithAnimation>
      <Box padding="24px">
        {IS_BOY_FIRST ? renderBoy() : renderGirl()}
        <Box height="24px" />
        {IS_BOY_FIRST ? renderGirl() : renderBoy()}
      </Box>
    </Box>
  );
}

export default SinglePhoto;
